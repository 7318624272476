import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 112.5"
      version="1.2"
      height={height}
    >
      <defs />
      <defs>
        <symbol overflow="visible" id="a">
          <path d="M11.5 1.3594c-1.918 0-3.5-.5742-4.75-1.7188-1.0117-.8437-1.9375-2.2734-2.7813-4.2969-.5234-1.3203-.9375-3.1875-1.25-5.5937-.2421-1.9453-.3593-3.789-.3593-5.5313-.043-.457-.0625-1.2421-.0625-2.3593v-3.875c0-1.5.0508-3.289.1562-5.375.1446-1.7813.4258-3.5938.8438-5.4375.4453-1.8125 1.1094-3.3477 1.9844-4.6094.875-1.3203 2.039-2.2578 3.5-2.8125.875-.25 1.6914-.375 2.453-.375 1.4688 0 2.2032.4922 2.2032 1.4688 0 .3867-.1328.6953-.3906.9218-.2617.2188-.6016.3282-1.0157.3282-.1484 0-.3906-.0508-.7343-.1563l-.7344-.1094c-.668 0-1.2617.2032-1.7813.6094-.5234.3984-.9609.9805-1.3125 1.75-.793 1.6797-1.328 3.5078-1.6093 5.4844-.3867 2.4492-.5938 4.5781-.625 6.3906-.0742 1.5625-.1094 3.2148-.1094 4.9531v1.4219c-.0313.4922-.0469 1.1016-.0469 1.8281 0 1.7813.082 3.5235.25 5.2188.1446 2.0312.5313 3.7773 1.1563 5.2343.5508 1.6368 1.457 2.7891 2.7187 3.4532.5938.3437 1.3203.5156 2.1875.5156 1.539 0 2.832-.7266 3.875-2.1875A36.7807 36.7807 0 0015-7.0781c-.1367-1.164-.3438-1.961-.625-2.3906-.0313 0-.1094.0117-.2344.0312-.125.0234-.3086.0469-.5468.0781l-1.25.1094c-1.1875 0-1.7813-.504-1.7813-1.5156 0-.3125.1719-.586.5156-.8282.3516-.25.8282-.4257 1.4219-.5312.9688-.25 1.836-.375 2.6094-.375.6953 0 1.2383.2656 1.625.7969.3828.5234.5781 1.1836.5781 1.9843 0 1.1485.1719 2.7149.5156 4.7032.3438 1.9492.5157 3.5.5157 4.6562 0 .375-.1211.7031-.3594.9844-.2422.2813-.539.4219-.8907.4219-.875 0-1.4687-.3672-1.7812-1.0938v-.0625l-1.1406.5781c-1.0547.5938-1.9453.8907-2.6719.8907zm0 0" />
        </symbol>
        <symbol overflow="visible" id="b">
          <path d="M12.8125.5156c-.668 0-1.3125-.4687-1.9375-1.4062v-.1563c-.1055-1.8437-.418-3.8945-.9375-6.1562-.5234-2.2696-1.289-5.0078-2.2969-8.2188-.7734-2.4062-1.418-4.5664-1.9375-6.4843v20.5937c0 .5625-.1015.9766-.2969 1.2344-.1875.2578-.5625.3906-1.125.3906-.418 0-.75-.1328-1-.3906-.2421-.2578-.3593-.5977-.3593-1.0156v-.422l.1094-15.375c.0703-4.5312.1093-9.6327.1093-15.3124v-6.4375c0-.414.082-.7032.25-.8594.1758-.1563.5469-.2344 1.1094-.2344.8633 0 1.6602.168 2.3906.5.7383.336 1.3672.7969 1.8906 1.3907 1.082 1.2187 1.7813 2.6835 2.0938 4.3906.1328.6875.2031 1.4843.2031 2.3906 0 .5313-.043 1.1094-.125 1.7344-.086.625-.1484 1.0273-.1875 1.2031-.0625.3438-.2187.8672-.4687 1.5625a313.431 313.431 0 00-.4688 1.3594l-.625 1.4687-.5312 1.3594c.039 1.25.2578 2.836.6562 4.75.4063 1.918.8516 3.6992 1.3438 5.3438.3437 1.1484.9687 3.0625 1.875 5.75l1.5156 4.5937v.1094c0 .5937-.0547 1.1015-.1563 1.5156-.1054.5508-.4687.8281-1.0937.8281zM5.5937-24.2656c.4883-.4766 1.082-1.1563 1.7813-2.0313l.7813-4.5c.0312-.1758.0468-.4218.0468-.7343 0-1.0079-.1875-2.1758-.5625-3.5-.2187-.625-.496-1.1563-.8281-1.5938-.3242-.4375-.664-.6719-1.0156-.7031l-.0938 5.2343c-.1054 2.336-.1562 4.1485-.1562 5.4376 0 1.2187.0156 2.0156.0469 2.3906zm0 0" />
        </symbol>
        <symbol overflow="visible" id="c">
          <path d="M9.9844 1.4688c-.9375 0-1.793-.3204-2.5625-.9532C6.6602-.1094 6-.957 5.4375-2.0313 4.4258-3.914 3.6758-6.5 3.1875-9.7813c-.3438-2.2578-.6055-4.875-.7813-7.8437l-.0468-2.7656v-2.8282c-.043-.9062-.0625-2-.0625-3.2812 0-2.0938.086-3.9414.2656-5.5469.1328-1.6758.4844-3.332 1.0469-4.9687.5508-1.4258 1.3515-2.4375 2.4062-3.0313.625-.2812 1.25-.4218 1.875-.4218 1.8516 0 3.3164.7343 4.3906 2.203 1.1875 1.6368 1.9727 3.465 2.3594 5.4845.5508 2.4374.8633 4.7734.9375 7 .1328 2.4804.2032 4.8203.2032 7.0156v2.9843c0 .586-.086 1.6993-.25 3.3438-.1485 1.2813-.2188 2.375-.2188 3.2813v.2187C15.1015-7.957 15-6.8047 15-5.4844c0 .9688-.211 1.9805-.625 3.0313-.418 1.043-1.0117 1.9297-1.7813 2.6562-.7617.8438-1.6328 1.2656-2.6093 1.2656zm.1093-2.875c.6954 0 1.2891-.6446 1.7813-1.9376.414-1.1562.7188-2.5546.9063-4.203.1875-1.6563.3515-3.5313.5-5.625.0703-.9063.1093-2.0704.1093-3.5l-.0625-4.8126c-.0625-1.8828-.2734-4.2383-.625-7.0625-.2812-2.4062-.8047-4.5312-1.5625-6.375-.3554-.875-.793-1.5508-1.3125-2.0312-.5234-.4883-1.0781-.7344-1.6718-.7344-.875 0-1.5235.6797-1.9375 2.0313-.5235 1.4296-.8711 2.996-1.047 4.703-.2109 1.8555-.328 3.6173-.3593 5.2813L4.75-23.9375c-.0313.4805-.0469 1.1406-.0469 1.9844v.2031c0 2.336.2422 5.211.7344 8.625C6-8.4883 6.8165-5.1914 7.8906-3.2344c.6953 1.2188 1.4297 1.8281 2.2031 1.8281zm0 0" />
        </symbol>
        <symbol overflow="visible" id="d">
          <path d="M8.3594.7344C7.078.7344 6.4375-.586 6.4375-3.2344c0-1.6758-.7266-7.664-2.1719-17.9687-1.4492-10.3008-2.3672-15.9219-2.75-16.8594.457-.832 1.0664-1.25 1.8281-1.25.8008 0 1.5508 1.8125 2.25 5.4375.6641 3.836 1.2227 8.2266 1.672 13.1719l.375 4.2343c.207 2.375.328 3.5782.3593 3.6094l.5156-4.3906.3125-2.7188c.457-3.9765.754-6.7851.8907-8.4218.1757-2.1563.2656-3.6328.2656-4.4375v-1c0-.3828.1406-.75.4218-1.0938.2813-.3515.5938-.5312.9375-.5312 1.2579 0 1.8907.5273 1.8907 1.5781v.7813c0 1.9921.1953 4.125.5937 6.4062.3946 2.2813.9766 5.0117 1.75 8.1875.4883 1.8438.9766 4.0898 1.4688 6.7344.1015-1.1446.4297-3.9297.9843-8.3594.5626-4.039.9727-7.4844 1.2344-10.3281.2578-2.8438.3907-5.5.3907-7.9688 0-.8008.1289-1.3672.3906-1.7031.2578-.332.7578-.5 1.5-.5.414 0 .7851.1328 1.1093.3906.332.2617.5.6055.5 1.0313 0 .375-.1718.7734-.5156 1.1875 0 2.5117-.1953 5.4492-.5781 8.8125-.3867 3.3672-.8555 6.5625-1.4063 9.5937-.5624 2.9688-1.039 6.1524-1.4218 9.5469-.375 3.3984-.5625 6.3867-.5625 8.9688 0 .5546-.1367.996-.4063 1.328-.2617.3321-.6523.5-1.1718.5-.3868 0-.8399-.7148-1.3594-2.1406-.6563-1.9882-1.4766-5.9453-2.4532-11.875l-.9375-6.1093-.4687-3.4063c-.461 3.1797-.8047 5.7344-1.0313 7.6719-.2187 1.9297-.4375 4.2148-.6562 6.8594-.2422 2.5547-.3594 5.0625-.3594 7.5312 0 .3555-.1523.6875-.4531 1-.293.3125-.6328.4688-1.0156.4688zm0 0" />
        </symbol>
        <symbol overflow="visible" id="e">
          <path d="M6.8438.625C6.5.625 6.1952.5 5.9375.25c-.2617-.2422-.3906-.5313-.3906-.875l.0937-5.0781c.1016-2.2578.1563-3.9453.1563-5.0625 0-1.0078-.1563-2.5078-.4688-4.5-.3437-1.9453-.5156-3.461-.5156-4.5469 0-1.3203-.1953-2.836-.5781-4.5469-.3867-1.707-.875-3.3476-1.4688-4.9218-.5547-1.5-1.0234-3.1094-1.4062-4.8282-.3867-1.7265-.5781-3.2383-.5781-4.5312 0-.2383.1406-.4453.4218-.625.2813-.1758.5781-.2657.8906-.2657.3829 0 .75.4024 1.0938 1.2032.414.9062.8164 2.1836 1.2031 3.8281.3828 1.6367.6133 2.6094.6875 2.9219l.6719 3.6093.5313 2.297c.1015-.5509.1875-1.2813.25-2.1876.207-2.8945.4687-5.3008.7812-7.2187.25-1.3203.5313-2.332.8438-3.0313.3437-.7695.7578-1.1562 1.25-1.1562.9453 0 1.4218.1797 1.4218.5312 0 1.9805-.4218 4.9063-1.2656 8.7813-.836 3.8672-1.25 6.8086-1.25 8.8281l-.1094.8438c-.0625.5234-.0781.8359-.0468.9374v.8438c0 1.4922.0507 3.789.1562 6.8906l.1094 6.9532.0937 1.203.1094 1.25v1.3126c0 .418-.1797.7734-.5313 1.0625C7.75.4727 7.332.625 6.8438.625zm0 0" />
        </symbol>
        <symbol overflow="visible" id="f">
          <path d="M12.2344.9375C10.078.9375 8.3672 0 7.1094-1.875c-1.3555-1.914-2.3125-4.2695-2.875-7.0625-.625-2.9688-1.0274-6.1406-1.2031-9.5156-.1797-3.0703-.2657-6.3985-.2657-9.9844v-4.3438c.039-1.2187.0625-2.5078.0625-3.875-.2812-.2695-.4219-.6132-.4219-1.0312 0-.4258.1563-.8008.4688-1.125.3125-.332.6602-.5 1.0469-.5 1.25 0 1.875 1.2344 1.875 3.7031 0 1.6055.086 4.6407.2656 9.1094.1758 4.461.2656 7.4922.2656 9.0938.1758.4492.3438 1.3554.5 2.7187.1563 1.3555.2344 2.4688.2344 3.3438 0 .7734.1172 1.7265.3594 2.8593.25 1.125.5976 2.2617 1.0469 3.4063.457 1.2304 1.0507 2.2344 1.7812 3.0156.7266.7813 1.4922 1.1719 2.2969 1.1719.4883 0 .8945-.1563 1.2187-.4688.332-.3125.5508-.7656.6563-1.3594.2187-.9765.3281-1.9375.3281-2.875v-10.1875c0-1.289-.125-2.9296-.375-4.9218-.211-2.1953-.3125-3.8516-.3125-4.9688v-.3593c0-.5626-.0156-1.0665-.0469-1.5157l-.0625-1.5156c0-.9766-.0547-2.461-.1562-4.4531-.1367-1.7696-.2032-3.2696-.2032-4.5 0-.375.1954-.7032.5938-.9844.4063-.2813.8516-.4219 1.3438-.4219l1.2968 1.2032v.8906c0 .418-.0703.9218-.2031 1.5156-.1367.9805-.2031 1.539-.2031 1.6719 0 3.1797.1718 7.5547.5156 13.125.3125 5.8554.4688 10.4023.4688 13.6406 0 1.75-.172 3.289-.5157 4.6094-.211.8047-.5 1.4492-.875 1.9375-.3672.4804-.8593.8945-1.4843 1.25-.5938.3437-1.3594.5156-2.297.5156zm0 0" />
        </symbol>
        <symbol overflow="visible" id="g">
          <path d="M6.1094 1.1563c-1.25 0-2.3281-.2305-3.2344-.6876C2.3125.1563 2.0312-.207 2.0312-.625c0-.414.1016-.754.3125-1.0156.2188-.2578.5157-.3906.8907-.3906.3515 0 .8594.1367 1.5156.4062.7383.2813 1.1914.4219 1.3594.4219.664 0 1.2695-.2852 1.8125-.8594.539-.5703.9687-1.332 1.2812-2.2813.625-1.7382.9375-3.3046.9375-4.703 0-1.5-.2343-3.0782-.7031-4.7345-.4688-1.6562-1.1055-3.2656-1.9063-4.828l-.8437-1.672c-.8672-1.75-1.539-3.1289-2.0156-4.1406-.4688-1.0078-.875-2.1015-1.2188-3.2812-.5234-1.6758-.7812-3.3008-.7812-4.875 0-1.25.211-2.4297.6406-3.5469.4375-1.1133 1.0938-2.0547 1.9688-2.8281.875-.832 1.953-1.25 3.2343-1.25.6328 0 1.2422.1836 1.8282.5468.5937.3672 1.0156.8282 1.2656 1.3907l-.8907 1.0937c-.9062 0-1.5898-.1718-2.0468-.5156-.625.0625-1.1719.3672-1.6407.9063-.4687.543-.828 1.164-1.078 1.8593a6.4568 6.4568 0 00-.3126 1.9844c0 1.3242.1797 2.6797.5469 4.0625.375 1.375.8594 2.6719 1.4531 3.8906l3.6563 7.3125c.5937 1.2617 1.0625 2.6797 1.4062 4.25.3516 1.5625.5313 3.125.5313 4.6875 0 2.3047-.5625 4.4649-1.6875 6.4844C10.9609-1.1953 10.1953-.3672 9.25.2344c-.9375.6133-1.9844.9219-3.1406.9219zm0 0" />
        </symbol>
        <symbol overflow="visible" id="h">
          <path d="M6.4375-.0469c-3 0-4.5-.5234-4.5-1.5781 0-.832.5195-1.25 1.5625-1.25h.625c.1445.0313.3008.0469.4688.0469.2812 0 .4843-.082.6093-.25.125-.1758.1875-.4922.1875-.9531 0-2.957-.1797-7-.5312-12.125-.3125-5.332-.4688-9.3594-.4688-12.0782 0-.9765.0156-1.8633.0469-2.6562L4.5-33.5625c0-1.1563-.0547-2.1133-.1563-2.875-.3867.1367-.789.2031-1.203.2031-.8048 0-1.2032-.414-1.2032-1.25 0-.414.25-.7812.75-1.0937.5078-.3125 1.164-.539 1.9688-.6875 1.457-.2383 2.6914-.3594 3.703-.3594.4259 0 .7852.1406 1.0782.4219.289.2734.4375.6015.4375.9843 0 .3868-.1172.7188-.3438 1-.2187.2735-.5078.4063-.8593.4063-.211 0-.3828-.0156-.5157-.0469h-.4218c-.3438 0-.5938.0782-.75.2344-.1563.1563-.2344.4453-.2344.8594 0 3.6992.2227 8.789.6719 15.2656.414 6.8047.625 11.914.625 15.3281v1.9844c.25-.0313.5976-.0469 1.0468-.0469 1.4688 0 2.2032.6094 2.2032 1.8281v.3126l-1.3125.8906c-.0313 0-.1367.0117-.3125.0312-.1797.0117-.5274.0156-1.0469.0156-1.1875.0743-1.918.1094-2.1875.1094zm0 0" />
        </symbol>
        <symbol overflow="visible" id="i">
          <path d="M5.5469.5781c-.461 0-.8711-.168-1.2344-.5-.3672-.332-.5469-.7226-.5469-1.1719 0-.3124.0664-.6953.2031-1.1562.1016-.4883.1563-.8516.1563-1.0938 0-3.1015-.1719-9.0625-.5156-17.875-.3555-7.5312-.5313-13.4726-.5313-17.828 0-.3204.1172-.5782.3594-.7657.25-.1875.5508-.2813.9063-.2813.414 0 .7812.1524 1.0937.4532.3125.293.4688.6484.4688 1.0625 0 2.086.1015 5.2187.3125 9.4062.1757 4.6367.2656 7.7735.2656 9.4063l.0469.625v1.2656c.1015 3.0313.1562 7.5625.1562 13.5938v1.3593h4.0313c.7265.5235 1.0937 1.043 1.0937 1.5625 0 .3867-.1328.7305-.3906 1.0313-.2617.293-.5625.4375-.9063.4375-.1054 0-.2968-.039-.5781-.1094l-.2188-.0469C9.5078-.0156 9.1798 0 8.7345 0h-.8907C7.5938-.0313 7.3125-.0469 7-.0469c-.3438.414-.8281.625-1.4531.625zm0 0" />
        </symbol>
        <symbol overflow="visible" id="j">
          <path d="M7.1563.8438c-.4493 0-.8516-.1954-1.2032-.5782-.3437-.3828-.5156-.8203-.5156-1.3125 0-.5195.1914-.9883.5781-1.4062.3828-.414.836-.625 1.3594-.625.4883 0 .9219.211 1.2969.625.3828.418.5781.871.5781 1.3594 0 .5546-.2031 1.0117-.6094 1.375-.3984.375-.8906.5625-1.4843.5625zm0 0" />
        </symbol>
        <symbol overflow="visible" id="k">
          <path d="M10.3594.8438c-.9805 0-1.8828-.2305-2.7032-.6875C6.8438-.2892 6.1406-.8984 5.547-1.672 5.0156-2.3984 4.539-3.332 4.125-4.4688 3.707-5.6015 3.3945-6.7813 3.1875-8c-.418-2.0508-.6953-4.754-.8281-8.1094-.043-.8008-.0625-2.1758-.0625-4.125v-3.2968c.039-.9376.0625-1.9102.0625-2.922 0-2.0507.1172-3.6718.3594-4.8593-.1055-.5195-.1563-1.0625-.1563-1.625 0-2.0938.7813-3.7813 2.3438-5.0625 1.5703-1.289 3.5625-1.9375 5.9687-1.9375.9063 0 1.7383.246 2.5.7344.7695.4804 1.1563 1.0898 1.1563 1.8281 0 .3438-.1407.621-.422.8281-.2734.211-.621.3125-1.0468.3125-.3438 0-.7188-.0508-1.125-.1562-.3984-.1016-.6484-.2578-.75-.4688h-.6719v-.1093c-1.293 0-2.375.2812-3.25.8437-.625.4492-1.1172 1.1992-1.4687 2.25-.1367.3867-.2422.875-.3125 1.4688-.0625.5859-.0938 1.121-.0938 1.6093-.043.2813-.0625.8399-.0625 1.6719l.1094 1.8281.0469 1.7813c0 .5234-.0703 1.1328-.2031 1.8281-.1797.875-.2657 1.4844-.2657 1.8281v1.2657c0 1.3554.0508 3.2187.1563 5.5937.039 1.9805.2695 3.9648.6875 5.9531.3125 1.8438.9062 3.414 1.7812 4.7032.832 1.3242 1.961 1.9843 3.3906 1.9843.5938 0 1.3282-.3125 2.2032-.9375.9375-.625 1.5976-.9375 1.9843-.9375.4883 0 .9922.2617 1.5157.7813 0 1.293-.4922 2.289-1.4688 2.9844-.9062.625-1.9843 1.0234-3.2343 1.203-.6993.0704-1.2579.1095-1.672.1095zm0 0" />
        </symbol>
        <symbol overflow="visible" id="l">
          <path d="M18.1875 1.3594c-.3438 0-.621-.1797-.8281-.5313-.211-.3437-.3125-.8437-.3125-1.5l-.0625-7.75c-.0313-.6562-.0469-1.7539-.0469-3.2968-.0742-3.2383-.1094-6.8946-.1094-10.9688v-2.6719c-.3125 1.6797-.8515 4.0313-1.6093 7.0625-.7735 3.1367-1.3125 5.7149-1.625 7.7344v.3125l-.1563 1c-.0742.7617-.25 1.543-.5313 2.3438-.3437.8437-.8828 1.2656-1.6093 1.2656-1.2617 0-1.8907-1.6406-1.8907-4.9219 0-1.8125-.1835-3.754-.5468-5.8281-.3672-2.0703-.9492-4.625-1.75-7.6563l-1.5625-5.8593-.1094 6.2812c0 1.875.086 5.4648.2656 10.7656l.0938 3.5469.1094 7.1719c0 .7304-.2618 1.3906-.7813 1.9844-.875 0-1.4805-.2696-1.8125-.8125-.3242-.5391-.4844-1.332-.4844-2.375l.0938-4.297c.1015-1.875.1562-3.285.1562-4.2343l-.0937-10.0781-.1094-10.1407c0-1.5.0352-3.75.1094-6.75l1.4062-.8437c.2813.043.5547.246.8281.6094.2813.3672.5977.9492.9532 1.75.8633 2.1562 1.9922 6.4804 3.3906 12.9687.207.9063.3477 1.6563.4219 2.25.414 2.0235.711 3.5547.8906 4.5938l.7813 4.0312.375-1.7343.9843-4.2813c.5625-2.5078 1.0235-4.7031 1.3907-6.5781.3632-1.8828.7187-3.9258 1.0625-6.125.3828-2.6133.6289-4.789.7343-6.5313v-.3125c.0703-.457.2813-.8164.625-1.0781.3516-.2578.7735-.3906 1.2657-.3906.3124 0 .5859.1406.828.4218.25.2735.375.5704.375.8907 0 4.1797.1563 10.4336.4688 18.7656.1758 4.0742.2969 8.0625.3594 11.9688.0703 1.7812.1094 4.0312.1094 6.75 0 .8046-.211 1.5156-.625 2.1406-.418.625-.8907.9375-1.4219.9375zm0 0" />
        </symbol>
      </defs>
      <use href="#a" x="29.6861" y="76.1519" />
      <use href="#b" x="51.2268" y="76.1519" />
      <use href="#c" x="68.4802" y="76.1519" />
      <use href="#d" x="89.1844" y="76.1519" />
      <use href="#e" x="117.156" y="76.1519" />
      <use href="#c" x="133.1547" y="76.1519" />
      <use href="#f" x="153.8588" y="76.1519" />
      <use href="#b" x="176.968" y="76.1519" />
      <use href="#g" x="194.2215" y="76.1519" />
      <use href="#c" x="212.4161" y="76.1519" />
      <use href="#h" x="233.1202" y="76.1519" />
      <use href="#i" x="248.7007" y="76.1519" />
      <use href="#j" x="263.9151" y="76.1519" />
      <use href="#k" x="281.1686" y="76.1519" />
      <use href="#c" x="300.9316" y="76.1519" />
      <use href="#l" x="321.6358" y="76.1519" />
    </svg>
  );
};

Logo.propTypes = {
  height: PropTypes.number,
};

Logo.defaultProps = {
  height: 50,
};

export default Logo;
