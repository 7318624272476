import React from 'react';
import PropTypes from 'prop-types';

import Footer from './footer/Footer';

const Layout = ({ children, withFooter }) => {
  return (
    <>
      <main>{children}</main>
      {withFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  withFooter: PropTypes.bool
};

Layout.defaultProps = {
  withFooter: false
};

export default Layout;
